/* eslint @typescript-eslint/no-unused-vars: off */
/**
 * bwlogin
 * Login - Barentswatch Login Signing Service
 *
 * The version of the OpenAPI document: 1.0.0-draft
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ApplicationAccessDto,
    ApplicationDto,
    AuthenticationCredentialDto,
    AuthenticatorResponseDto,
    ComputerUserInfoDto,
    DiscoveryDocumentDto,
    DutyStationDto,
    EmailVerificationResultDto,
    IdentityProviderDto,
    InvitationDto,
    JwksDocumentDto,
    JwksKeyDto,
    JwtHeaderDto,
    JwtPayloadDto,
    OrganizationCreationDto,
    OrganizationDataScopesDto,
    OrganizationDto,
    SimpleUserInfoDto,
    SmsInvitationDto,
    SmsInvitationRegistrationDto,
    StatusDto,
    SubscriptionDto,
    TokenResponseDto,
    UserInfoDto,
    UserQueryDto,
} from "./model";

import { BaseAPI, SecurityScheme } from "./base";

export interface ApplicationApis {
    certificateAuthorizationServicesApi: CertificateAuthorizationServicesApiInterface;
    certificateManagerApi: CertificateManagerApiInterface;
    computerUsersApi: ComputerUsersApiInterface;
    defaultApi: DefaultApiInterface;
    loginApi: LoginApiInterface;
    userInfoApi: UserInfoApiInterface;
    userManagerApi: UserManagerApiInterface;
}

/**
 * CertificateAuthorizationServicesApi - object-oriented interface
 */
export interface CertificateAuthorizationServicesApiInterface {
    /**
     *
     * @summary Authorize sha256 fingerprint according to implemented rules
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    authorizesha256fingerprint(params: {
        queryParams?: { fingerprint: string, };
        headers: { "Authorization": string };
    }): Promise<ComputerUserInfoDto>;
}

/**
 * CertificateAuthorizationServicesApi - object-oriented interface
 */
export class CertificateAuthorizationServicesApi extends BaseAPI implements CertificateAuthorizationServicesApiInterface {
    /**
     *
     * @summary Authorize sha256 fingerprint according to implemented rules
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async authorizesha256fingerprint(params: {
        queryParams?: { fingerprint: string,  };
        headers: { "Authorization": string };
    }): Promise<ComputerUserInfoDto> {
        return await this.fetch(
            this.url("/api/certificates/authorizesha256fingerprint", {}, params?.queryParams, {}),
            {
                method: "POST",
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
}
/**
 * CertificateManagerApi - object-oriented interface
 */
export interface CertificateManagerApiInterface {
    /**
     *
     * @summary Authorize that the certificate is valid and has access to the requesting application
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    authorizeCertificate(params: {
        queryParams?: { certificate?: string, };
        headers?: { "Authorization"?: string };
    }): Promise<Array<ComputerUserInfoDto>>;
    /**
     *
     * @summary Authorize that the fingerprint is valid and has access to the requesting application
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    authorizeCertificateWithFingerprint(params: {
        queryParams?: { fingerprint: string, };
        headers: { "Authorization": string };
    }): Promise<ComputerUserInfoDto>;
}

/**
 * CertificateManagerApi - object-oriented interface
 */
export class CertificateManagerApi extends BaseAPI implements CertificateManagerApiInterface {
    /**
     *
     * @summary Authorize that the certificate is valid and has access to the requesting application
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async authorizeCertificate(params: {
        queryParams?: { certificate?: string,  };
        headers?: { "Authorization"?: string };
    }): Promise<Array<ComputerUserInfoDto>> {
        return await this.fetch(
            this.url("/api/certificates/authorize", {}, params?.queryParams, {}),
            {
                method: "POST",
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
    /**
     *
     * @summary Authorize that the fingerprint is valid and has access to the requesting application
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async authorizeCertificateWithFingerprint(params: {
        queryParams?: { fingerprint: string,  };
        headers: { "Authorization": string };
    }): Promise<ComputerUserInfoDto> {
        return await this.fetch(
            this.url("/api/certificates/authorizefingerprint", {}, params?.queryParams, {}),
            {
                method: "POST",
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
}
/**
 * ComputerUsersApi - object-oriented interface
 */
export interface ComputerUsersApiInterface {
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    computersimpleuser(params: {
        queryParams?: { organizationName?: string, organizationId?: string, };
        headers: { "Authorization": string };
    }): Promise<Array<SimpleUserInfoDto>>;
}

/**
 * ComputerUsersApi - object-oriented interface
 */
export class ComputerUsersApi extends BaseAPI implements ComputerUsersApiInterface {
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async computersimpleuser(params: {
        queryParams?: { organizationName?: string, organizationId?: string,  };
        headers: { "Authorization": string };
    }): Promise<Array<SimpleUserInfoDto>> {
        return await this.fetch(
            this.url("/api/computersimpleuser", {}, params?.queryParams, {}),
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
}
/**
 * DefaultApi - object-oriented interface
 */
export interface DefaultApiInterface {
    /**
     *
     * @throws {HttpError}
     */
    getJwksKeys(): Promise<JwksDocumentDto>;
    /**
     *
     * @throws {HttpError}
     */
    getOpenidConfiguration(): Promise<DiscoveryDocumentDto>;
}

/**
 * DefaultApi - object-oriented interface
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     *
     * @throws {HttpError}
     */
    public async getJwksKeys(): Promise<JwksDocumentDto> {
        return await this.fetch(
            this.basePath + "/.well-known/keys"
        );
    }
    /**
     *
     * @throws {HttpError}
     */
    public async getOpenidConfiguration(): Promise<DiscoveryDocumentDto> {
        return await this.fetch(
            this.basePath + "/.well-known/openid-configuration"
        );
    }
}
/**
 * LoginApi - object-oriented interface
 */
export interface LoginApiInterface {
    /**
     *
     * @summary Redirects the user to log in
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    authorize(params: {
        queryParams?: { scope: string, client_id?: string, redirect_uri?: string, state?: string, domain_hint?: string, org_hint?: string, };
    }): Promise<void>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    fetchToken(params: {
        formParams: { code: string; clientId: string; clientSecret: string; redirectUri: string; subjectToken: string; audience: string; }
        headers?: { "Authorization"?: string };
    }): Promise<TokenResponseDto>;
    /**
     *
     * @summary SSO status
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    getStatus(params: {
        queryParams?: { client_id: string, origin: string, };
    }): Promise<StatusDto>;
    /**
     *
     * @summary Who are you, and which applications can you access
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    getUserinfo(params: {
        queryParams?: { x509certificate?: string, };
        headers?: { "Authorization"?: string };
    }): Promise<UserInfoDto>;
    /**
     *
     * @summary Select organization
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    listOrganizationsForLogin(params: {
        queryParams?: { applicationName?: string, };
    }): Promise<Array<OrganizationDto>>;
    /**
     *
     * @summary Register that the user doesn\&#x27;t wish to be asked about webauthn
     * @throws {HttpError}
     */
    refuseWebauthn(): Promise<void>;
    /**
     *
     * @summary Register a webauthn credential associated with the current user
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    registerCredential(params: {
        authenticationCredentialDto?: AuthenticationCredentialDto;
    }): Promise<void>;
    /**
     *
     * @summary Redirects the user back to the application that requested authentication
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    selectOrganization(params: {
        queryParams?: { organizationId?: string, };
    }): Promise<void>;
    /**
     *
     * @summary Complete login with webauthn
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    verifyCredentials(params: {
        pathParams: { credentialId: string };
        authenticatorResponseDto?: AuthenticatorResponseDto;
    }): Promise<void>;
    /**
     *
     * @summary Verifies the users one time code for membership
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    verifyEmail(params: {
        queryParams?: { oneTimeCode: string, applicationName: string, organizationId: string, };
    }): Promise<EmailVerificationResultDto>;
}

/**
 * LoginApi - object-oriented interface
 */
export class LoginApi extends BaseAPI implements LoginApiInterface {
    /**
     *
     * @summary Redirects the user to log in
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async authorize(params: {
        queryParams?: { scope: string, client_id?: string, redirect_uri?: string, state?: string, domain_hint?: string, org_hint?: string,  };
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/login/authorize", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async fetchToken(params: {
        formParams: { code: string; clientId: string; clientSecret: string; redirectUri: string; subjectToken: string; audience: string; }
        headers?: { "Authorization"?: string };
    }): Promise<TokenResponseDto> {
        return await this.fetch(
            this.basePath + "/api/login/token",
            {
                method: "POST",
                body: this.formData(params.formParams),
                headers: {
                    ...this.removeEmpty(params.headers),
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }
        );
    }
    /**
     *
     * @summary SSO status
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getStatus(params: {
        queryParams?: { client_id: string, origin: string,  };
    }): Promise<StatusDto> {
        return await this.fetch(
            this.url("/api/login/status", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @summary Who are you, and which applications can you access
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getUserinfo(params: {
        queryParams?: { x509certificate?: string,  };
        headers?: { "Authorization"?: string };
    }): Promise<UserInfoDto> {
        return await this.fetch(
            this.url("/api/login/userinfo", {}, params?.queryParams, {}),
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
    /**
     *
     * @summary Select organization
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async listOrganizationsForLogin(params: {
        queryParams?: { applicationName?: string,  };
    }): Promise<Array<OrganizationDto>> {
        return await this.fetch(
            this.url("/api/login/organizations", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @summary Register that the user doesn\'t wish to be asked about webauthn
     * @throws {HttpError}
     */
    public async refuseWebauthn(): Promise<void> {
        return await this.fetch(
            this.basePath + "/api/login/refuseWebauthn",
            {
                method: "POST",
            }
        );
    }
    /**
     *
     * @summary Register a webauthn credential associated with the current user
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async registerCredential(params: {
        authenticationCredentialDto?: AuthenticationCredentialDto;
    }): Promise<void> {
        return await this.fetch(
            this.basePath + "/api/login/credentials",
            {
                method: "POST",
                body: JSON.stringify(params.authenticationCredentialDto),
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );
    }
    /**
     *
     * @summary Redirects the user back to the application that requested authentication
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async selectOrganization(params: {
        queryParams?: { organizationId?: string,  };
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/login/selectOrganization", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @summary Complete login with webauthn
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async verifyCredentials(params: {
        pathParams: { credentialId: string };
        authenticatorResponseDto?: AuthenticatorResponseDto;
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/login/credentials/{credentialId}/verification", params.pathParams),
            {
                method: "POST",
                body: JSON.stringify(params.authenticatorResponseDto),
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );
    }
    /**
     *
     * @summary Verifies the users one time code for membership
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async verifyEmail(params: {
        queryParams?: { oneTimeCode: string, applicationName: string, organizationId: string,  };
    }): Promise<EmailVerificationResultDto> {
        return await this.fetch(
            this.url("/api/login/verifyEmail", {}, params?.queryParams, {})
        );
    }
}
/**
 * UserInfoApi - object-oriented interface
 */
export interface UserInfoApiInterface {
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    listOrganizationDataScopes(params: {
        headers: { "Authorization": string };
    }): Promise<Array<OrganizationDataScopesDto>>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    listUserMemberships(params: {
        pathParams: { personId: string };
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    listUsers(params: {
        queryParams?: { organizationName?: string, organizationId?: string, };
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    queryUsers(params: {
        userQueryDto: UserQueryDto;
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    simpleuser(params: {
        queryParams?: { organizationName?: string, organizationId?: string, };
        headers: { "Authorization": string };
    }): Promise<Array<SimpleUserInfoDto>>;
}

/**
 * UserInfoApi - object-oriented interface
 */
export class UserInfoApi extends BaseAPI implements UserInfoApiInterface {
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async listOrganizationDataScopes(params: {
        headers: { "Authorization": string };
    }): Promise<Array<OrganizationDataScopesDto>> {
        return await this.fetch(
            this.basePath + "/api/users/organization/datascopes",
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async listUserMemberships(params: {
        pathParams: { personId: string };
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>> {
        return await this.fetch(
            this.url("/api/users/{personId}", params.pathParams),
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async listUsers(params: {
        queryParams?: { organizationName?: string, organizationId?: string,  };
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>> {
        return await this.fetch(
            this.url("/api/users/", {}, params?.queryParams, {}),
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async queryUsers(params: {
        userQueryDto: UserQueryDto;
        headers: { "Authorization": string };
    }): Promise<Array<UserInfoDto>> {
        return await this.fetch(
            this.basePath + "/api/users/query",
            {
                method: "POST",
                body: JSON.stringify(params.userQueryDto),
                headers: {
                    ...this.removeEmpty(params.headers),
                    "Content-Type": "application/json",
                }
            }
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async simpleuser(params: {
        queryParams?: { organizationName?: string, organizationId?: string,  };
        headers: { "Authorization": string };
    }): Promise<Array<SimpleUserInfoDto>> {
        return await this.fetch(
            this.url("/api/simpleuser", {}, params?.queryParams, {}),
            {
                headers: {
                    ...this.removeEmpty(params.headers),
                }
            }
        );
    }
}
/**
 * UserManagerApi - object-oriented interface
 */
export interface UserManagerApiInterface {
    /**
     *
     * @summary Complete registration by adding givenName and familyName
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    completeRegistration(params: {
        pathParams: { invitationId: string };
        formParams: { givenName: string; familyName: string; title: string; dutystationId: string; }
    }): Promise<void>;
    /**
     *
     * @summary Retrieve invitation information
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    fetchInvitation(params: {
        pathParams: { invitationCode: string };
    }): Promise<InvitationDto>;
    /**
     *
     * @summary Fetch sms invitation with given id
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    fetchSmsInvitation(params: {
        pathParams: { smsInvitationId: string };
    }): Promise<SmsInvitationDto>;
    /**
     *
     * @summary Find sms invitation for phone number
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    findSmsInvitation(params: {
        queryParams?: { phonenumber: string, };
    }): Promise<SmsInvitationDto>;
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    getDutystationsForSubscriptions(params: {
        queryParams?: { subscriptionIds: Array<string>, };
    }): Promise<Array<DutyStationDto>>;
    /**
     *
     * @summary Send phone verification code
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    sendPhoneVerificationCode(params: {
        pathParams: { invitationId: string };
    }): Promise<void>;
    /**
     *
     * @summary Complete registration of user with name
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    smsInvitationCompleteRegistration(params: {
        pathParams: { smsInvitationId: string };
        smsInvitationRegistrationDto: SmsInvitationRegistrationDto;
    }): Promise<void>;
    /**
     *
     * @summary Send verification code by sms to phone number in sms invitation
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    smsInvitationSendVerificationCode(params: {
        pathParams: { smsInvitationId: string };
    }): Promise<void>;
    /**
     *
     * @summary Verify phone number with code sent by SMS
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    smsInvitationVerifyCode(params: {
        pathParams: { smsInvitationId: string };
        queryParams?: { phoneVerificationCode: string, };
    }): Promise<void>;
    /**
     *
     * @summary Verify code sent via SMS
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    verifyPhoneNumber(params: {
        pathParams: { invitationId: string };
        formParams: { phoneVerificationCode: string; }
    }): Promise<void>;
}

/**
 * UserManagerApi - object-oriented interface
 */
export class UserManagerApi extends BaseAPI implements UserManagerApiInterface {
    /**
     *
     * @summary Complete registration by adding givenName and familyName
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async completeRegistration(params: {
        pathParams: { invitationId: string };
        formParams: { givenName: string; familyName: string; title: string; dutystationId: string; }
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/invitations/{invitationId}/completeRegistration", params.pathParams),
            {
                method: "POST",
                body: this.formData(params.formParams),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }
        );
    }
    /**
     *
     * @summary Retrieve invitation information
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async fetchInvitation(params: {
        pathParams: { invitationCode: string };
    }): Promise<InvitationDto> {
        return await this.fetch(
            this.url("/api/invitations/{invitationCode}", params.pathParams)
        );
    }
    /**
     *
     * @summary Fetch sms invitation with given id
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async fetchSmsInvitation(params: {
        pathParams: { smsInvitationId: string };
    }): Promise<SmsInvitationDto> {
        return await this.fetch(
            this.url("/api/smsInvitation/{smsInvitationId}", params.pathParams)
        );
    }
    /**
     *
     * @summary Find sms invitation for phone number
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async findSmsInvitation(params: {
        queryParams?: { phonenumber: string,  };
    }): Promise<SmsInvitationDto> {
        return await this.fetch(
            this.url("/api/smsInvitation", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async getDutystationsForSubscriptions(params: {
        queryParams?: { subscriptionIds: Array<string>,  };
    }): Promise<Array<DutyStationDto>> {
        return await this.fetch(
            this.url("/api/dutystation", {}, params?.queryParams, {})
        );
    }
    /**
     *
     * @summary Send phone verification code
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async sendPhoneVerificationCode(params: {
        pathParams: { invitationId: string };
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/invitations/{invitationId}/sendPhoneVerificationCode", params.pathParams),
            {
                method: "POST",
            }
        );
    }
    /**
     *
     * @summary Complete registration of user with name
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async smsInvitationCompleteRegistration(params: {
        pathParams: { smsInvitationId: string };
        smsInvitationRegistrationDto: SmsInvitationRegistrationDto;
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/smsInvitation/{smsInvitationId}/completeRegistration", params.pathParams),
            {
                method: "POST",
                body: JSON.stringify(params.smsInvitationRegistrationDto),
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );
    }
    /**
     *
     * @summary Send verification code by sms to phone number in sms invitation
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async smsInvitationSendVerificationCode(params: {
        pathParams: { smsInvitationId: string };
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/smsInvitation/{smsInvitationId}/sendPhoneVerificationCode", params.pathParams),
            {
                method: "POST",
            }
        );
    }
    /**
     *
     * @summary Verify phone number with code sent by SMS
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async smsInvitationVerifyCode(params: {
        pathParams: { smsInvitationId: string };
        queryParams?: { phoneVerificationCode: string,  };
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/smsInvitation/{smsInvitationId}/verifyCode", params.pathParams, params?.queryParams, {}),
            {
                method: "POST",
            }
        );
    }
    /**
     *
     * @summary Verify code sent via SMS
     * @param {*} [params] Request parameters, including pathParams, queryParams (including bodyParams) and http options.
     * @throws {HttpError}
     */
    public async verifyPhoneNumber(params: {
        pathParams: { invitationId: string };
        formParams: { phoneVerificationCode: string; }
    }): Promise<void> {
        return await this.fetch(
            this.url("/api/invitations/{invitationId}/verifyPhoneNumber", params.pathParams),
            {
                method: "POST",
                body: this.formData(params.formParams),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }
        );
    }
}

type ServerNames =
    | "Origin"
    | "Production"
    | "Integration test";

export const servers: Record<ServerNames, ApplicationApis> = {
    "Origin": {
        certificateAuthorizationServicesApi: new CertificateAuthorizationServicesApi(""),
        certificateManagerApi: new CertificateManagerApi(""),
        computerUsersApi: new ComputerUsersApi(""),
        defaultApi: new DefaultApi(""),
        loginApi: new LoginApi(""),
        userInfoApi: new UserInfoApi(""),
        userManagerApi: new UserManagerApi(""),
    },
    "Production": {
        certificateAuthorizationServicesApi: new CertificateAuthorizationServicesApi("https://login.barentswatch.net"),
        certificateManagerApi: new CertificateManagerApi("https://login.barentswatch.net"),
        computerUsersApi: new ComputerUsersApi("https://login.barentswatch.net"),
        defaultApi: new DefaultApi("https://login.barentswatch.net"),
        loginApi: new LoginApi("https://login.barentswatch.net"),
        userInfoApi: new UserInfoApi("https://login.barentswatch.net"),
        userManagerApi: new UserManagerApi("https://login.barentswatch.net"),
    },
    "Integration test": {
        certificateAuthorizationServicesApi: new CertificateAuthorizationServicesApi("https://login9.barentswatch.net"),
        certificateManagerApi: new CertificateManagerApi("https://login9.barentswatch.net"),
        computerUsersApi: new ComputerUsersApi("https://login9.barentswatch.net"),
        defaultApi: new DefaultApi("https://login9.barentswatch.net"),
        loginApi: new LoginApi("https://login9.barentswatch.net"),
        userInfoApi: new UserInfoApi("https://login9.barentswatch.net"),
        userManagerApi: new UserManagerApi("https://login9.barentswatch.net"),
    },
};

