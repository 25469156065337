import { useEffect, useState } from "react";
import { LoginApi, UserInfoDto } from "./generated";
import { LoggedOutError } from "./generated/base";

export function useUserinfo() {
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(true);
    const [userinfo, setUserinfo] = useState<UserInfoDto | undefined>();

    const loginController = new LoginApi();

    useEffect(() => {
        (async () => {
            async function handleStorageEvent(event: StorageEvent) {
                if (event.key === "userinfo") {
                    try {
                        setUserinfo(await loginController.getUserinfo({}));
                    } catch (e) {
                        console.log("Error", e);
                        setUserinfo(undefined);
                    } finally {
                        setError(undefined);
                        setLoading(false);
                    }
                }
            }

            addEventListener("storage", handleStorageEvent);

            setLoading(true);
            setUserinfo(undefined);
            try {
                const userinfo = await loginController.getUserinfo({});
                if (userinfo) {
                    window.localStorage.setItem("userinfo", JSON.stringify(userinfo));
                    if (window.location.search === "?redirect=true") {
                        window.location.href = "/api/login/redirect";
                    } else {
                        setUserinfo(userinfo);
                        setLoading(false);
                    }
                } else {
                    window.localStorage.removeItem("userinfo");
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                if (e instanceof LoggedOutError) {
                    setUserinfo(undefined);
                } else {
                    setError(e as Error);
                }
            }
            return () => removeEventListener("storage", handleStorageEvent);
        })();
    }, []);

    return { userinfo, loading, error };
}
