import * as React from "react";
import * as cls from "classnames";

export type ButtonProps = Omit<GenericButtonProps, "style">;

export function PrimaryButton({ children, ...props }: ButtonProps) {
    return (
        <GenericButton {...props} style="primary">
            {children}
        </GenericButton>
    );
}

export function SecondaryButton({ children, ...props }: ButtonProps) {
    return (
        <GenericButton {...props} style={"secondary"}>
            {children}
        </GenericButton>
    );
}

export function OutlinedPrimaryButton({ children, ...props }: ButtonProps) {
    return (
        <GenericButton {...props} style="outlined-primary">
            {children}
        </GenericButton>
    );
}

export function CancelButton({ children, ...props }: ButtonProps) {
    return (
        <GenericButton type="reset" {...props} style="cancel">
            {children}
        </GenericButton>
    );
}

interface GenericButtonProps {
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    style: string;
    className?: string;
    testid?: string;
}

function GenericButton({ disabled, onClick, children, type, style, testid, className }: GenericButtonProps) {
    return (
        <button
            type={type || "button"}
            className={cls("button", style, className, { disabled })}
            onClick={onClick}
            disabled={disabled}
            data-testid={testid}
        >
            <div className={"state"} />
            {children}
        </button>
    );
}
