/**
 * bwlogin
 * Login - Barentswatch Login Signing Service
 *
 * The version of the OpenAPI document: 1.0.0-draft
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ApplicationAccessDto {
    readonly id?: string;
    memberId: string;
    canInvite: boolean;
    personId?: string;
    readonly fullName?: string;
    subscriptionId: string;
    readonly applicationName?: string;
    readonly memberEmailAddress?: string;
}

export interface ApplicationDto {
    name: string;
    title: string;
    showInApplicationSelector: boolean;
    landingPage: string;
    smsInvitationAllowed?: boolean;
}

export interface AuthenticationCredentialDto {
    credentialId?: string;
    attestationObject?: string;
    clientDataJSON?: string;
}

export interface AuthenticatorResponseDto {
    authenticatorData: string;
    signature: string;
    clientDataJSON: string;
}

export interface ComputerUserInfoDto {
    membershipId: string;
    description: string;
    organization?: string;
    organizationId: string;
    phoneNumber?: string;
    email?: string;
    title?: string;
    availableApplications: Array<ApplicationDto>;
    scopes?: Array<string>;
}

export interface DiscoveryDocumentDto {
    issuer?: string;
    authorization_endpoint?: string;
    token_endpoint?: string;
    revocation_endpoint?: string;
    userinfo_endpoint?: string;
    end_session_endpoint?: string;
    jwks_uri?: string;
    response_types_supported?: Array<DiscoveryDocumentDtoResponseTypesSupportedEnum>;
    response_modes_supported?: Array<DiscoveryDocumentDtoResponseModesSupportedEnum>;
    subject_types_supported?: Array<DiscoveryDocumentDtoSubjectTypesSupportedEnum>;
    code_challenge_methods_supported?: Array<DiscoveryDocumentDtoCodeChallengeMethodsSupportedEnum>;
    id_token_signing_alg_values_supported?: Array<DiscoveryDocumentDtoIdTokenSigningAlgValuesSupportedEnum>;
    x_sso_frame?: string;
}

export type DiscoveryDocumentDtoResponseTypesSupportedEnum =
    | "code"
    | "token"
    | "id_token";

export const DiscoveryDocumentDtoResponseTypesSupportedEnumValues: DiscoveryDocumentDtoResponseTypesSupportedEnum[] = [
    "code",
    "token",
    "id_token",
];

export type DiscoveryDocumentDtoResponseModesSupportedEnum =
    | "query"
    | "fragment";

export const DiscoveryDocumentDtoResponseModesSupportedEnumValues: DiscoveryDocumentDtoResponseModesSupportedEnum[] = [
    "query",
    "fragment",
];

export type DiscoveryDocumentDtoSubjectTypesSupportedEnum =
    | "pairwise"
    | "public";

export const DiscoveryDocumentDtoSubjectTypesSupportedEnumValues: DiscoveryDocumentDtoSubjectTypesSupportedEnum[] = [
    "pairwise",
    "public",
];

export type DiscoveryDocumentDtoCodeChallengeMethodsSupportedEnum =
    | "S256"
    | "plain";

export const DiscoveryDocumentDtoCodeChallengeMethodsSupportedEnumValues: DiscoveryDocumentDtoCodeChallengeMethodsSupportedEnum[] = [
    "S256",
    "plain",
];

export type DiscoveryDocumentDtoIdTokenSigningAlgValuesSupportedEnum =
    | "RS256";

export const DiscoveryDocumentDtoIdTokenSigningAlgValuesSupportedEnumValues: DiscoveryDocumentDtoIdTokenSigningAlgValuesSupportedEnum[] = [
    "RS256",
];

export interface DutyStationDto {
    id: string;
    name: string;
    description?: string;
    subscriptionId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface EmailVerificationResultDto {
    success: boolean;
    goToUrl?: string;
}

export interface IdentityProviderDto {
    name: string;
    label: string;
}

export interface InvitationDto {
    readonly id?: string;
    subscriptionId: string;
    emailAddress: string;
    mobilePhone?: string;
    title?: string;
    isPhoneConfirmationCodeSent?: boolean;
    isPhoneNumberValidated?: boolean;
    /**
     * If true, the invited user can give others access to this organization and application
     */
    canInviteNewMembers: boolean;
    /**
     * If true, the invited user can create certificates for this subscription
     */
    certificateIssuer: boolean;
    /**
     * When the invitation was created
     */
    readonly createdAt?: Date;
    /**
     * If set, returns when the invitation was converted into a user\'s access
     */
    readonly completedAt?: Date;
    /**
     * If set, the membership of the user who accepted the invitation
     */
    readonly resultingMembershipId?: string;
    /**
     * The id of the membership that granted the access
     */
    readonly granterId?: string;
    /**
     * The title of the application associated with the invitation
     */
    readonly applicationTitle?: string;
}

export interface JwksDocumentDto {
    keys?: Array<JwksKeyDto>;
}

export interface JwksKeyDto {
    kty?: "RSA";
    use?: string;
    kid: string;
    /**
     * Public key exponent
     */
    e: string;
    /**
     * Public key modulus
     */
    n: string;
    /**
     * X.509 Certificates (base 64 encoded)
     */
    x5c?: Array<string>;
}

export interface JwtHeaderDto {
    /**
     * Type of JWT
     */
    typ?: string;
    /**
     * Key ID - used to look up the public key/X.509 certificate in the JWKS
     */
    kid?: string;
    /**
     * Algorithm used to sign the JWT. Normally RS256 (RSA signed SHA-256)
     */
    alg?: string;
}

export interface JwtPayloadDto {
    /**
     * Issuer - the URI for the login provider
     */
    iss: string;
    /**
     * Subject - the unique identifier for the logged in user
     */
    sub: string;
    /**
     * Audience - the client_id that the JWT was issued to
     */
    aud: string;
    /**
     * The name of the logged in user
     */
    name?: string;
    /**
     * The email of the logged in user
     */
    email?: string;
    /**
     * The unique identifier of the organization that the user has selected
     */
    orgId?: string;
    /**
     * The name of the organization that the user has selected
     */
    org?: string;
    /**
     * For JWT from ID-porten, contains national identity number
     */
    pid?: string;
}

export interface OrganizationCreationDto {
    name: string;
    emailDomain?: string;
    subscriptions: Array<SubscriptionDto>;
}

export interface OrganizationDataScopesDto {
    organizationId?: string;
    organization?: string;
    scopes?: Array<string>;
}

export interface OrganizationDto {
    id: string;
    name: string;
    acronym?: string;
    isUserAdmin: boolean;
    /**
     * If true, this organization can create other organizations
     */
    isMasterOrganization: boolean;
    emailDomains?: Array<string>;
}

export interface SimpleUserInfoDto {
    membershipId: string;
    personId: string;
}

export interface SmsInvitationDto {
    id: string;
    mobilePhone: string;
    givenName?: string;
    familyName?: string;
    emailAddress?: string;
    /**
     * Remaining number of attempts to validate phone number
     */
    readonly remainingValidationAttempts?: number;
    /**
     * Timestamp for last attempt to send verification code on sms
     */
    readonly verificationCodeLastSent?: Date;
    /**
     * If true, the phone number has beed verified
     */
    readonly isPhoneNumberVerified?: boolean;
    /**
     * It true, invitation has been completed and access has been granted
     */
    readonly isCompleted?: boolean;
}

export interface SmsInvitationRegistrationDto {
    givenName: string;
    familyName: string;
    email: string;
}

export interface StatusDto {
    userinfo?: UserInfoDto;
    targetOrigin?: string;
}

export interface SubscriptionDto {
    readonly id?: string;
    applicationName: string;
    readonly applicationTitle?: string;
    /**
     * If true, new members of the organization will automatically be given access to this subscription
     */
    allUsersHaveAccess: boolean;
}

/**
 * Token response according to https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
 */
export interface TokenResponseDto {
    access_token: string;
    /**
     * The type of token this is. Always the string \'bearer\'
     */
    token_type: string;
    /**
     * The duration of time the access token is granted for in seconds
     */
    expires_in?: number;
    /**
     * Space separated list of granted scopes for the access_token
     */
    scope?: string;
    /**
     * JWT which has format base64(JwtHeader) + \'.\' base64(JwtPayload) + \'.\' + base64(signature)
     */
    id_token?: string;
    refresh_token?: string;
}

export interface UserInfoDto {
    fullName: string;
    givenName?: string;
    familyName?: string;
    membership: string;
    /**
     * True if the user can update users for at least one application in this organization
     */
    isAdmin?: boolean;
    /**
     * True if the user can invite new members for at least one application in this organization
     */
    canInviteNewMembers?: boolean;
    /**
     * True if the user can issue certificates for the selected application in this organization
     */
    isCertificateIssuer?: boolean;
    /**
     * The users title
     */
    title?: string;
    personId: string;
    organization: string;
    phoneNumber?: string;
    verifiedPhoneNumbers?: Array<string>;
    email?: string;
    organizationId: string;
    organizationAcronym?: string;
    /**
     * True if the organization can create and manage new organizations
     */
    isMasterOrganization?: boolean;
    /**
     * True if user has access to create organizations
     */
    canCreateOrganizations?: boolean;
    availableApplications: Array<ApplicationDto>;
    availableOrganizations: Array<OrganizationDto>;
    availableIdentityProviders: Array<IdentityProviderDto>;
    acceptedInvitations?: Array<string>;
    acceptedSmsInvitations?: Array<string>;
    clientApplication?: ApplicationDto;
    /**
     * Audience - the application for which the introspected token was intented
     */
    aud?: string;
    sid?: string;
    dutystationName?: string;
    scopes?: Array<string>;
}

export interface UserQueryDto {
    /**
     * Norwegian nation id number
     */
    pid?: string;
    /**
     * Hashed Norwegian nation id number
     */
    obfuscatedPid?: string;
}
